import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { SectionTitle, ImageSharpFluid } from 'helpers/definitions';

import * as Styled from './styles';
import FormatHtml from 'components/utils/FormatHtml';

const HochzeitsbilderPosts: React.FC = () => {
	const { markdownRemark } = useStaticQuery(graphql`
		query {
			markdownRemark(frontmatter: { category: { eq: "hochzeitsbilder section" } }) {
				frontmatter {
					title
				}
				html
			}
		}
	`);

	const sectionTitle: SectionTitle = markdownRemark.frontmatter;

	return (
		<Container section>
			<TitleSection title={sectionTitle.title} center />
			<div>{markdownRemark.html && <FormatHtml content={markdownRemark.html} />}</div>
		</Container>
	);
};

export default HochzeitsbilderPosts;
