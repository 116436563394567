import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HochzeitsbilderPosts from 'components/HochzeitsbilderPosts';

const HochzeitsbilderPage: React.FC = () => {
	return (
		<Layout>
			<SEO title="Hochzeitsbilder" />
			<HochzeitsbilderPosts />
		</Layout>
	);
};

export default HochzeitsbilderPage;
